(function () {

  'use strict';

  let isMobile = {
    Android: function() {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function() {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
  };

  let scrollLink = function() {

    $('.nav-link').click(function () {
      console.log("duh");
      let sectionTo = $(this).attr('href');
      $('html, body').animate({
        scrollTop: $(sectionTo).offset().top
      }, 1500);
    });
  };

  let fullHeight = function() {

    if ( !isMobile.any() ) {
      $('.js-fullheight').css('height', $(window).height());
      $(window).resize(function(){
        $('.js-fullheight').css('height', $(window).height());
      });
    }

  };


  let counter = function() {
    $('.js-counter').countTo({
      formatter: function (value, options) {
        return value.toFixed(options.decimals);
      },
    });
  };




  // Animations
  let contentWayPoint = function() {
    let i = 0;


    var waypoint = new Waypoint({
      element: document.getElementsByClassName('animate-box'),
      offset: '85%',
      handler: function(direction) {
        if( direction === 'down' && !$(this.element).hasClass('animated') ) {

          i++;

          $(this.element).addClass('item-animate');
          setTimeout(function(){

            $('body .animate-box.item-animate').each(function(k){
              const el = $(this);
              setTimeout( function () {
                const effect = el.data('animate-effect');
                if ( effect === 'fadeIn') {
                  el.addClass('fadeIn animated');
                } else if ( effect === 'fadeInLeft') {
                  el.addClass('fadeInLeft animated');
                } else if ( effect === 'fadeInRight') {
                  el.addClass('fadeInRight animated');
                } else {
                  el.addClass('fadeInUp animated');
                }

                el.removeClass('item-animate');
              },  k * 200, 'easeInOutExpo' );
            });

          }, 100);

        }
      }
    });
  };


  let burgerMenu = function() {
    const body = $('body');
    $('.js-colorlib-nav-toggle').on('click', function(event){
      event.preventDefault();
      const $this = $(this);

      if (body.hasClass('offcanvas')) {
        $this.removeClass('active');
        body.removeClass('offcanvas');
      } else {
        $this.addClass('active');
        body.addClass('offcanvas');
      }
    });



  };

  // Click outside of offcanvass
  let mobileMenuOutsideClick = function() {
    const body = $('body');
    $(document).click(function (e) {
      const container = $("#colorlib-aside, .js-colorlib-nav-toggle");

      if (!container.is(e.target) && container.has(e.target).length === 0) {

        if ( body.hasClass('offcanvas') ) {

          body.removeClass('offcanvas');
          $('.js-colorlib-nav-toggle').removeClass('active');

        }

      }
    });

    $(window).scroll(function(){
      if ( body.hasClass('offcanvas') ) {

        body.removeClass('offcanvas');
        $('.js-colorlib-nav-toggle').removeClass('active');

      }
    });

  };

  let sliderMain = function() {

    $('#colorlib-hero .flexslider').flexslider({
      animation: "fade",
      slideshowSpeed: 3000,
      directionNav: true,
      start: function(){
        setTimeout(function(){
          $('.slider-text').removeClass('animated fadeInUp');
          $('.flex-active-slide').find('.slider-text').addClass('animated fadeInUp');
        }, 500);
      },
      before: function(){
        setTimeout(function(){
          $('.slider-text').removeClass('animated fadeInUp');
          $('.flex-active-slide').find('.slider-text').addClass('animated fadeInUp');
        }, 500);
      }

    });

  };


  // Document on load.
  $(function(){
    fullHeight();
    counter();
    //counterWayPoint();
    contentWayPoint();
    burgerMenu();
    mobileMenuOutsideClick();
    sliderMain();
    scrollLink();
  });


}());

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
  event.preventDefault();
  $(this).ekkoLightbox({

    alwaysShowClose: true
  });
});